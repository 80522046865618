import axios from "axios";

//const BASE_URL = "http://localhost:5000/api/";
//const BASE_URL = "https://olive-hare-kilt.cyclic.app/";
const BASE_URL = "https://api-ecomm.onrender.com/api/";

//const TOKEN = JSON.parse(JSON.parse(localStorage.getItem("persist:root")).user).currentUser.accessToken;

const TOKEN =localStorage.getItem("persist:root")?JSON.parse(JSON.parse(localStorage.getItem("persist:root"))?.user).currentUser?.accessToken:"";

export const publicRequest = axios.create({
  baseURL: BASE_URL,
});

export const userRequest = axios.create({
  baseURL: BASE_URL,
  headers: { token: `Bearer ${TOKEN}` },
});
